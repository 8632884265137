/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
  				url 		=> router path
  				name 		=> name to display in sidebar
  				slug 		=> router path name
  				icon 		=> Feather Icon component/icon name
  				tag 		=> text to display on badge
  				tagColor 	=> class to apply on badge element
  				i18n 		=> Internationalization
  				submenu 	=> submenu of current item (current item will become dropdown )
  							NOTE: Submenu don't have any icon(you can add icon if u want to display)
  				isDisabled 	=> disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Version: 1.1
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: "/",
    name: "Dashboard",
    slug: "home",
    icon: "LayersIcon",
  },
  {
    header: "Master",
  },
  {
    url: "/meta",
    name: "Meta",
    slug: "meta",
    icon: "TargetIcon",
  },
  {
    url: "",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
    submenu: [
      {
        url: "/home-header",
        name: "Banner",
        slug: "layout",
        icon: "CopyIcon",
      },
      {
        url: "/home-section-first",
        name: "Home Content",
        slug: "layout",
        icon: "CopyIcon",
      },
      {
        url: "/home-section-second",
        name: "Home Offering",
        slug: "layout",
        icon: "CopyIcon",
      },
      {
        url: "/home-section-third",
        name: "Home Why",
        slug: "layout",
        icon: "CopyIcon",
      },
    ],
  },
  {
    url: "",
    name: "Tours",
    slug: "tours",
    icon: "NavigationIcon",
    submenu: [
      {
        url: "/tours1",
        name: "Tours",
        slug: "tour",
        icon: "CopyIcon",
      },
      {
        url: "/tours2",
        name: "Tours Detail",
        slug: "toursdetail",
        icon: "CopyIcon",
      },
      {
        url: "/tours3",
        name: "Tours Itinerary",
        slug: "toursitinerary",
        icon: "CopyIcon",
      },
    ],
  },
  {
    url: "",
    name: "Story",
    slug: "story",
    icon: "ClockIcon",
    submenu: [
      {
        url: "/story-header",
        name: "Story Banner",
        slug: "story-header",
        icon: "CopyIcon",
      },
      {
        url: "/story1",
        name: "Story Content",
        slug: "story",
        icon: "CopyIcon",
      },
      {
        url: "/team",
        name: "Story Team",
        slug: "story",
        icon: "CopyIcon",
      },
    ],
  },
  {
    url: "/tips",
    name: "Tips",
    slug: "tips",
    icon: "MessageSquareIcon",
  },
  {
    url: "/review",
    name: "Review",
    slug: "review",
    icon: "EditIcon",
  },
  {
    url: "/blog",
    name: "Blog",
    slug: "blog",
    icon: "Edit2Icon",
  },
  {
    url: "/contact",
    name: "Message Received",
    slug: "contact",
    icon: "MailIcon",
  },
  {
    url: "/payment",
    name: "Payment",
    slug: "payment",
    icon: "DollarSignIcon",
  },
  {
    header: "Settings",
  },
  {
    url: "/website",
    name: "Contact Section",
    slug: "website",
    icon: "GlobeIcon",
  },
  {
    url: "/password",
    name: "Change Password",
    slug: "password",
    icon: "KeyIcon",
  },
];
